var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "notice-view-wrapper" },
    [
      _c("module-box", { attrs: { appModule: _vm.appModule } }, [
        _c("div", { attrs: { slot: "search" }, slot: "search" }, [
          _c(
            "div",
            {
              staticClass: "more-wrapper",
              style: { color: _vm.theme.worktop_minor_text_color },
              on: { click: _vm.moreNoticeClick },
            },
            [_vm._v("更多")]
          ),
        ]),
        _vm.noticeList.length > 0
          ? _c(
              "div",
              { staticClass: "list-wrapper" },
              _vm._l(_vm.noticeList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "cell-wrapper",
                    on: {
                      click: function ($event) {
                        return _vm.click(item)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "flex" }, [
                      _c("img", { attrs: { src: _vm.gonggao, alt: "" } }),
                      _c("div", { staticClass: "cell-title" }, [
                        _vm._v(
                          _vm._s(
                            `【${item.noticeTypeName}】${item.noticeTitle}`
                          )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "cell-time",
                        style: { color: _vm.theme.worktop_minor_text_color },
                      },
                      [_vm._v(_vm._s(item.noticeTimeStart))]
                    ),
                  ]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }