<!--
 * @Description: 平台公告
 * @Author: 小广
 * @Date: 2020-01-06 09:53:40
 * @LastEditors: xfj
 * @LastEditTime: 2020-03-03 14:19:38
 -->
<template>
  <div class="notice-view-wrapper">
    <module-box :appModule="appModule">
      <div slot="search">
        <div class="more-wrapper" :style="{'color':theme.worktop_minor_text_color}" @click="moreNoticeClick">更多</div>
      </div>
      <div class="list-wrapper"
           v-if="noticeList.length > 0">
        <div class="cell-wrapper"
             v-for="(item, index) in noticeList"
             :key="index"
             @click="click(item)">
             <div class="flex">
               <img :src="gonggao" alt="">
                <div class="cell-title">{{`【${item.noticeTypeName}】${item.noticeTitle}`}}</div>
             </div>
          <div class="cell-time" :style="{'color':theme.worktop_minor_text_color}">{{item.noticeTimeStart}}</div>
        </div>
      </div>
    </module-box>
  </div>
</template>

<script >
import ModuleBox from '../ModuleBox'
import Store from '@/store'
import gonggao from '../images/gonggao.png'

export default {
  name: 'notice',
  // 引用的模块
  components: {
    ModuleBox
  },
  props: {
    appModule: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      title: '平台公告',
      params: {
        executeSearch: 1,
        curPage: 1,
        pageSize: 5,
        maxPage: 0,
        maxRow: 0,
        row: 0,
        // 正常状态
        status: 1,
        workbenchSearchFlag: true
      },
      gonggao,
      noticeList: []
    }
  },

  // created函数
  created () {
    this.requestForData()
  },

  computed: {
    viewPermission () {
      // 权限读取
      let view = false
      let permission = this.$store.state.menuStore.menuPermission.announcement1
      if (permission.view === 1) {
        view = true
      }
      return view
    },

    theme () {
      return this.$store.state.theme
    }
  },

  mounted () {
  },

  // 方法
  methods: {
    click (row) {
      if (!this.viewPermission) {
        this.$message('您没有查看权限，请联系管理员添加')
        return false
      }
      this.$emit('clickNotice', row)
      // let menuMap = Store.getters['menuStore/getMenuMap']
      // let query = menuMap['announcement1'] || {}
      // this.$router.push({
      //   name: 'platformNoticeForm',
      //   query: {
      //     id: row.id,
      //     ...query
      //   }
      // })
    },

    moreNoticeClick () {
      if (!this.viewPermission) {
        this.$message('您没有查看权限，请联系管理员添加')
        return false
      }
      // 跳转到平台公告列表
      this.$router.push({
        name: 'announcement1'
      })
    },

    async requestForData () {
      let requestURL = `${API_CONFIG.butlerBaseURL}platformNotice/listPlatformNotice`
      const { data, status } = await this.$axios.get(requestURL, { params: this.params })
      if (status === 100) {
        if (data) {
          this.noticeList = data.resultList || []
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.notice-view-wrapper {
  //  display: flex;
  // background: white;
  width: 100%;
  // position: relative;
  //  padding: 24px;

  .more-wrapper {
    position: absolute;
    font-size:12px;
    font-weight:400;
    line-height:20px;
    color: #AAAAAA;
    width: 60px;
    text-align: right;
    top: 14px;
    right: 50px;

    &:hover {
      cursor: pointer;
    }
  }

  .list-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;

    .cell-wrapper {
      width: 100%;
      margin: 5px 0;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .flex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .cell-title {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: #475F7B;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .cell-time {
        width: 120px;
        line-height: 17px;
        font-size: 12px;
        font-weight: 400;
        text-align: right;
        color: #aaaaaa;
        margin-left: 34px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
